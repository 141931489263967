$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.tenderingAndBiddingContent {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: $mainPadding;
    padding-bottom: 5px;
    overflow: hidden;

    .biddingTitleDiv {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .biddingTitle {
            font-weight: bold;
            font-size: 24px;
            color: #000000;
        }
    }

    .iconBox {
        font-size: 14px;
        font-weight: 500;
        color: #0068B2;
        cursor: pointer;
        user-select: none;

        .anticon {
            margin-right: 5px
        }

        &:hover {
            text-decoration: underline
        }
    }

    .tabBox {
        height: calc(100% - 25px);
        width: 100%;
    }

    .annotationDiv {
        width: 100%;
        text-align: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #9E9E9E;
        margin-top: 24px;
    }

    .infoBox {
        // padding-left: 10px;
        width: 100%;
        height: 100%;
        overflow: hidden;
        
        .tableTypeBox {
            height: 40px;
            width: 100%;
            display: flex;
            align-items: center;

            div {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0 16px;
                margin-right: 20px;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                border: 1px solid #D8D8D8;
                cursor: pointer;

                span {
                    margin-left: 3px;
                    font-size: 18px;
                    font-weight: 600;
                }
            }

            .activeItem {
                background: #0068B2;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                border: 1px solid #0068B2;
                color: #fff;
            }
        }

        .tableTypeContent {
            width: 100%;
            height: calc(100% - 60px);
            // margin-top: 20px;
        }
    }

    .ant-tabs-tab .anticon {
        margin-right: 0px;
    }

    .ant-tabs-content-holder {
        height: 100% !important;
        margin-top: 25px;
    }

    .myTabs {
        .ant-tabs-tab:first-of-type {
            margin-left: 0 !important;
        }
    }

    .chartsSpin {
        .ant-spin {
            max-height: none !important;
        }
    }

    .numberSpan {
        color: #A44119;
        font-weight: 500;
    }

    .companySpan {
        color: #313131;
        font-weight: 500;
    }

    .clickSpan {
        color: #003666;
        font-weight: 500;
    }
}