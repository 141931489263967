$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.PersonAndCompanySelectItem {
    padding: 3px 12px;

    .addComp {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;
        line-height: 16px;

        .name {
            color: rgba(0, 0, 0, .85);
            display: flex;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;

            .nameTag {
                display: flex;
                flex: none;
                height: 16px;
                width: 50px;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                text-align: center;
                padding: 0px;
                border-radius: 20px;
                margin-right: 6px;
            }

            .nameText{
                display: inline-block;
                white-space: nowrap;
                // width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .info {
            display: inline-block;
            white-space: nowrap;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #8492a6;
            font-size: 10px
        }
    }
}

.PersonAndCompanyModal {
    z-index: 1050;
    .ant-modal {
        border-radius: 10px;

        .ant-modal-header {
            padding: 10px 20px;
        }

        .ant-modal-close-x {
            height: 43px;
            line-height: 43px;
        }

        .ant-modal-content {
            border-radius: 10px;
            overflow: hidden;
        }

        .ant-modal-body {
            padding: 20px;
            padding-bottom: 0;
        }

        #detailHtmlBox {
            padding: 20px;
            max-height: 80vh;
            overflow: auto;
            @include scrollbar;
        }
    }

    .formContent {
        .ant-form-item {
            .ant-form-item-label {
                width: 80px;
            }
        }
        .tip{
            margin-bottom: 10px;
            padding-left: 80px;
            margin-top: 10px;
            color: red;
        }
    }
}