$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.yearSelect {
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #6BBADB;
    margin-right: 6px;
    background: rgba(107, 186, 219, 0.15);
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #6BBADB;
    padding: 0 8px;

    .ant-select-selector {
        padding: 0 !important;
    }

    .ant-select {
        margin-left: 2px;
        font-size: 12px !important;
        color: #6BBADB !important;

        .ant-select-selection-item {
            color: #6BBADB !important;
        }
    }

    .yearItem {
        height: 18px;
        width: 34px;
        border-bottom: 1px solid #D8D8D8;
        padding: 0 5px;
        position: relative;
        top: -4px;
        cursor: pointer;
        user-select: none;

        &:hover {
            color: #0068B2;

            span {
                display: block;
            }
        }

        span {
            display: none;
            position: absolute;
            bottom: -4px;
            right: 12px;
            width: 0;
            height: 0;
            border-bottom: 6px solid #0068B2;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
        }
    }

    .activeYearItem {
        color: #0068B2;

        span {
            display: block;

        }
    }
}

.chartYearSelectPopup {
    .ant-select-item {
        font-size: 12px !important;
        min-height: 24px !important;
        padding: 2px 12px !important;
    }

}