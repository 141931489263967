$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.regionMapContent {
    width: 100%;
    // height: 100%;
    // padding: 16px;
    position: relative;

    .headerBox {
        z-index: 10;
        height: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-family: PingFang SC-粗体, PingFang SC;
        font-weight: bold;
        color: #313131;

        .headerBoxTitle {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .block {
            display: inline-block;
            width: 2px;
            height: 16px;
            background: linear-gradient(223deg, #006BB3 0%, #15D2D6 100%);
            margin-right: 10px;
        }

        .downloadIcon {
            font-size: 16px;
            cursor: pointer;
        }

        .yearSelect {
            // position: absolute;
            // bottom: 10px;
            // right: 10px;
            z-index: 56;
        }
    }

    .selectBox {
        // position: absolute;
        // top: 50px;
        // left: 16px;
        height: 20px;
        // width: 55px;
        z-index: 56;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        margin-top: 12px;
        padding-left: 12px;

        .selectItem {
            @include flex-c-c;
            // width: 24px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #313131;
            height: 18px;
            background: #FFFFFF;
            // border-radius: 2px 2px 2px 2px;
            // border: 1px solid #D8D8D8;
            cursor: pointer;
            margin-right: 12px;

            .selectItemIcon {
                width: 100%;
                height: 100%;
                color: #999999;

                div {
                    @include flex-c-c;
                    width: 100%;
                    height: 100%;
                }

                svg {
                    display: block;
                    height: 80%;
                    width: auto;
                }
            }

        }

        .activeSelectItem {
            color: #006BB3;
            border-bottom: 1px solid #006BB3;

            .selectItemIcon {
                color: #fff;
            }
        }
    }

    .mapTextDiv {
        position: absolute;
        bottom: 20px;
        left: 12px;
        font-family: PingFang SC, PingFang SC;
        font-size: 14px;
        color: #313131;
        z-index: 10;
    }

    #regionMapBox {
        width: calc(100% - 12px);
        aspect-ratio: 2 / 1;
        margin-left: 12px;
        z-index: 8;

        ~div,
        canvas {
            width: 100% !important;
            height: 100% !important;
        }

        .emptyContent {
            width: 100%;
            height: 100%;
            @include flex-c-c;
        }
    }

    .emptyContent {
        width: 100%;
        height: 100%;
        @include flex-c-c;
    }
}