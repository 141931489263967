$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.competitivenessAnalysisContent {
    width: 100%;
    height: calc(100% - 15px);
    background: #fff;

    .dataInput {
        font-size: 14px;
        font-family: PingFang SC-中等, PingFang SC;
        font-weight: normal;
        color: #9E9E9E;
        margin-bottom: 10px;
    }

    .lineDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 22px;
        width: 100%;
        font-size: 14px;
        font-family: PingFang SC-中等, PingFang SC;
        font-weight: normal;
        color: #0068B2;

        i {
            display: inline-block;
            width: 100%;
            height: 1px;
            background: #0068B2;
        }

        span {
            display: inline-block;
            flex: none;
            padding: 0 16px;
        }
    }

    .checkBox {
        overflow-y: auto;
        @include scrollbar;
        width: 100%;
        // padding: 0 16px;
        height: calc(100% - 85px);
        margin-top: 12px;
        padding-right: 10px;
    }


    .chartsBox {
        width: 100%;
        // height: calc(100% - 34px);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .imgTitleBox {
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                display: block;
                width: 14px;
                height: 14px;
                margin-right: 3px;
            }
        }

        .chartsLeftBox {
            width: 450px;
            height: 100%;

            .chartBox:first-of-type {
                margin-top: 0;
                padding-right: 36px;
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                border-right: 1px solid #EFEFEF;
            }
        }

        .chartsRightBox {
            width: calc(100% - 450px);
            height: 100%;
            // margin-left: 20px;
            padding-left: 36px;

            // margin-left: 20px;
            .chartsRightScrollbarsBox {
                div {
                    &:nth-of-type(3) {
                        z-index: 55;
                    }
                }
            }
        }

        .chartBox {
            width: 100%;
            // height: 330px;
            // aspect-ratio: 2.4 / 1;
            padding-right: 12px;
            background: #FFFFFF;
            // border-radius: 10px 10px 10px 10px;
            opacity: 1;
            // border: 1px solid rgba(0, 104, 178, 0.1);
            flex: none;
            margin-top: 60px;
            box-sizing: border-box;

            &:nth-of-type(1) {
                margin-top: 0;
            }

            .headerTitleBox {
                font-size: 14px;
                font-family: PingFang SC-粗体, PingFang SC;
                font-weight: bold;
                color: #313131;
                // padding: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .anticon {
                    font-size: 16px;
                    cursor: pointer;
                }
            }

            .tableContentBox {
                height: calc(100% - 54px);
                width: 100%;
                // padding: 0 16px 16px 16px;
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;

                .paginationBox {
                    margin-top: 13px;
                    display: flex;
                    flex-direction: row-reverse;
                }

                .searchBox {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    // justify-content: space-between;
                    margin-bottom: 12px;
                    margin-top: 12px;

                    .searchItemBox {
                        width: 80px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        user-select: none;
                        cursor: pointer;

                        &:hover {
                            color: #0068B2;
                        }

                        &:focus {
                            color: #0068B2;
                        }
                    }

                    .searchBoxSelectItem {
                        width: calc(100% - 76px);
                        // margin: 0 0 0 16px;
                        display: flex;
                        align-items: center;
                        position: relative;

                        .searchBoxSelectIcon {
                            position: absolute;
                            font-size: 16px;
                            color: #bfbfbf;
                            top: 9px;
                            left: 8px;
                            z-index: 56;
                        }
                    }

                    .searchBoxSelect {
                        .ant-select-selector {
                            border-radius: 18px !important;
                        }

                        .ant-select-selection-search,
                        .ant-select-selection-placeholder {
                            padding-left: 16px;
                        }
                    }
                }

                .tipTextContentBox {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    padding: 0 20px;
                    color: #535353;
                    margin-bottom: 10px;

                    .span1 {
                        font-weight: bold;
                    }

                    .span2 {
                        color: #0068B2;
                        font-weight: bold;
                    }

                    .span3 {
                        cursor: pointer;

                        &:hover {
                            color: #0068B2;
                        }
                    }
                }

                .sortContentBox {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 14px;
                    margin-top: 12px;
                    color: #535353;

                    .sortIconItem {
                        cursor: pointer;
                        margin-right: 20px;
                        display: flex;
                        align-items: center;

                        &:hover {
                            color: #0068B2;
                        }

                        .sortReactSvg {
                            margin-left: 3px;
                        }
                    }

                    .sortIconActiveItem {
                        color: #0068B2;
                    }

                    .sortItem {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        .sortItemText {
                            margin-left: 10px;
                            color: #0068B2;
                            display: flex;
                            align-items: center;

                            .sortReactSvg {
                                margin-left: 2px;
                            }

                            path,
                            svg,
                            rect {
                                fill: #0068B2;
                            }
                        }
                    }

                    .handleIconBox {
                        display: flex;
                        align-items: center;

                        .searchItemBox {
                            // width: 80px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            user-select: none;
                            cursor: pointer;
                            margin-left: 10px;

                            .anticon {
                                margin-right: 2px;

                                svg {
                                    width: 14px;
                                    height: 14px;
                                }
                            }

                            &:hover {
                                color: #0068B2;

                                svg,
                                path,
                                g {
                                    fill: #0068B2;
                                }
                            }

                            &:focus {
                                color: #0068B2;

                                svg,
                                path,
                                g {
                                    fill: #0068B2;
                                }
                            }
                        }
                    }
                }

                .dividerBox {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin-top: 12px;

                    span {
                        display: inline-block;
                        width: 100%;
                        height: 1px;
                        background: #9E9E9E;
                    }
                }

                .searchCompanyBox {
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                }
            }
        }

        .comOrPeopleContent {
            width: 100%;
            height: 100%;
            text-align: left;
            display: flex;
            align-items: center;
        }

        .comOrPeopleLogo {
            height: 30px;
            width: 30px;
            flex: none;
            border-radius: 50%;
            background: #0068B2;
            @include flex-c-c;
            margin-right: 5px;
            flex: none;

            img {
                display: inline-block;
                width: 20px;
                height: 20px;
            }

        }

        .tagBox {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 3px;

            .ant-tag {
                font-size: 9.5px;
                border-radius: 10px;
                zoom: 0.8;
                background: #EFEFEF;
                border-color: #EFEFEF;
                color: #313131;
                margin-right: 8px;
                margin-top: 8px;
            }
        }
    }

    .deleteIcon {
        color: #535353;
        cursor: pointer;

        &:hover {
            color: #0068B2;
        }

        div {
            @include flex-c-c;
        }
    }


}

.tableColumnsParagraphTooltip {
    max-width: 300px;

    .tableColumnsParagraphTip {
        max-height: 225px;
        // width: 438px;
        font-size: 12px;
        width: auto;
        margin-left: 0px;
        padding: 0;
        overflow-y: auto;
        @include tooltipScrollbar;
        white-space: pre-wrap;
    }
}

.dialogDrawer {
    .dialogDrawerFormItemRequireSpan {
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        box-sizing: border-box;
    }

    .addCompetitorForm {
        padding: 20px;

        .ant-radio-group {
            margin: 12px 0;
        }
    }

    .addAllDiv{
        &:hover{
            color: #0068B2;
        }
        cursor: pointer;
        // float: right;
        width: 100%;
        text-align: right;
        padding-right: 10px;

    }

    .fileUpDiv {
        .uploadFileDrawerContent {
            width: 100%;
            height: 100%;

            .downloadTemplate {
                height: 30px;
                margin-bottom: 20px;
                @include flex;
                @include flex-j-s-b;

                .iconBox {
                    font-size: 14px;
                    font-weight: 500;
                    color: #0068B2;
                    cursor: pointer;
                    user-select: none;
                    @include flex-c-c;

                    // text-decoration:underline;
                    .text {
                        border-bottom: 1px solid #0068B2;
                    }

                    .anticon {
                        font-size: 16px;
                        margin-right: 5px
                    }
                }


            }

            .draggerBox {
                height: 160px;
            }

            .uploadBox {
                width: 100%;
                height: 100%;
                @include flex-c-c;
                flex-direction: column;

                .anticon {
                    height: 66px;
                    width: 66px;
                    color: #D8D8D8;

                    svg {
                        height: 66px;
                        width: 66px;
                    }
                }

                .text {
                    font-size: 14px;
                    font-weight: normal;
                    color: #9E9E9E;
                    padding: 0 3px;
                    margin-top: 10px;

                    span {
                        color: #0068B2;
                    }
                }
            }

            .fileTip {
                font-size: 12px;
                font-family: PingFang SC-常规, PingFang SC;
                font-weight: normal;
                color: #9E9E9E;
                margin: 8px 0;
            }

            .fileLineItem {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
                cursor: pointer;
            }

            .fileItem {
                @include flex-c-c;

                .ant-typography {
                    margin-left: 5px;
                    margin-bottom: 0;
                }
            }

            .closeIcon:hover {
                color: #0068B2;
            }
        }
    }
}

.addCompetitorTable {
    .ant-table-cell {
        padding: 10px 2px;
    }

    .ant-table-placeholder>td {
        border-bottom: none !important;
    }

    .ant-table-row {
        // border-bottom: 1px solid rgba(0, 0, 0, .06);
    }

    .addCompetitorFormTableCellItem {

        .titleBox {
            // width: calc(100% - 80px);
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 400;
            color: #313131;
            // word-wrap: break-word;
        }

        .tagsBox {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .ant-tag {
                margin-bottom: 6px;
            }
        }

        .ant-tag {
            font-size: 9.5px;
            border-radius: 10px;
            zoom: 0.8;
            background: #EFEFEF;
            border-color: #EFEFEF;
            color: #313131;
        }

        .iconTextBox {
            display: flex;
            align-items: center;
            justify-content: end;
            flex: none;
            color: #9E9E9E;

            .selectItemIcon {
                width: 14px;
                height: 14px;
                margin-right: 5px;

                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                svg {
                    width: 14px;
                    height: 14px;
                }
            }

            .text {
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #0068B2;

                span {
                    color: #9E9E9E;
                }
            }
        }


    }
}