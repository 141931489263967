$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
// html,
// body,
// #root {
//     min-width: 1200px !important;
// }

.hoverDownloadIcon {
    &:hover {
        color: #0068B2 !important;
    }
}

.cxhcBtn {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 100px;
}

.verificationReportPage {
    width: 100%;
    height: calc(100% - 48px);
    background: #F7F7F7;
    padding: 24px;
    display: flex;
    justify-content: center;

    #verificationReportPageDownloadContent {
        display: none;
        position: fixed;
        top: 200px,
    }

    .btnIcon {
        display: inline-block;
        width: 16px;
        height: 16px;
        text-decoration: none !important;
        margin-right: 4px;
        margin-left: 10px;
        font-size: 16px;
        color: #0068B2;
    }

    .reportTablePagination {
        // float: right;
        font-size: 12px !important;
        display: flex;
        align-items: center;
        justify-content: end;

        .ant-pagination-item,
        .ant-pagination-prev,
        .ant-pagination-next {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 26px !important;
            min-height: 26px !important;
            height: 26px !important;
            width: 26px !important;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .tableDetailBtn {
        color: #0068B2;
        cursor: pointer;
        user-select: none;
    }
}

.allAdoptPageContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // flex-wrap: wrap;
    width: 100%;
    height: calc(100% - 70px);

    .ant-result {
        width: 100%;
        padding: 20px;
    }

    .anticon {
        color: #5ABE6B !important;
        font-size: 60px;
    }

    .ant-result-title {
        font-weight: bold;
    }

    .clickSpan {
        color: #0068B2;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 5px;
    }

    .allAdoptPageTableBox {
        width: 460px;
        height: 120px;
        display: flex;
        align-items: center;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid rgba(0, 104, 178, 0.1);

        .tableItem {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 25%;
            height: 100%;
            font-weight: 400;
            font-size: 14px;
            color: #535353;
            border-right: 1px solid rgba(0, 104, 178, 0.1);

            &:last-of-type {
                border-right: none
            }

            .tableInnerItem {
                height: 33.3%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top: 1px solid rgba(0, 104, 178, 0.1);

                &:first-of-type {
                    border-top: none;
                }
            }
        }

        .tableActiveItem {
            background: rgba(0, 104, 178, 0.05);
        }
    }
}

.detailItemTableTdSharesTip {
    z-index: 56;

    // .ant-tooltip-arrow{
    //     width: 14px;
    //     height: 14px;
    // }
    .ant-tooltip-inner {
        min-height: 20px;
        max-height: 50vh;
        padding: 2px 4px;
        font-size: 12px;
        border-radius: 4px;
        user-select: none;
        @include tooltipScrollbar;
        overflow-y: auto;
    }
}

.verificationReportLoadingBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .verificationReportLoadingText {
        color: #535353;
        font-weight: bold;

        span {
            font-weight: unset;
            color: #0068B2;
            cursor: pointer;
            margin-left: 5px;
        }
    }
}