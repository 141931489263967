$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.supplierAnalysisContent {
    width: 100%;
    // height: 100%;
    // padding: 16px;
    position: relative;

    .annotationDiv {
        margin-top: 0;
        margin-bottom: 12px;
    }

    .selectBox {
        height: 20px;
        z-index: 56;
        display: flex;
        align-items: center;
        margin-top: 12px;
        padding-left: 12px;

        .selectItem {
            @include flex-c-c;
            height: 18px;
            background: #FFFFFF;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            font-size: 14px;
            color: #313131;
            cursor: pointer;
            margin-right: 12px;

            .selectItemIcon {
                width: 100%;
                height: 100%;
                color: #999999;

                div {
                    @include flex-c-c;
                    width: 100%;
                    height: 100%;
                }

                svg {
                    display: block;
                    height: 80%;
                    width: auto;
                }
            }

        }

        .activeSelectItem {
            color: #006BB3;
            border-bottom: 1px solid #006BB3;

            .selectItemIcon {
                color: #fff;
            }
        }
    }

    .headerBox {
        height: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-family: PingFang SC-粗体, PingFang SC;
        font-weight: bold;
        color: #313131;

        .headerBoxTitle {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .block {
            display: inline-block;
            width: 2px;
            height: 16px;
            background: linear-gradient(223deg, #006BB3 0%, #15D2D6 100%);
            margin-right: 10px;
        }

        .downloadIcon {
            font-size: 16px;
            cursor: pointer;
        }

        .yearSelect {
            // position: absolute;
            // top: 6px;
            // right: 10px;
            // z-index: 99;
        }
    }

    .topSelect {
        position: absolute;
        z-index: 66;
        right: 289px;
        top: 36px;
        color: #999999 !important;
    }

    .shareProportionContentBox {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 12px;

        .emptyContent {
            width: 100%;
            height: 100%;
            @include flex-c-c;
        }
    }

    #shareProportionBox {
        // height: calc(100% - 24px);
        // width: calc(100% - 280px);
        width: 80%;
        aspect-ratio: 2 / 1;
        margin-left: 12px;

        // ~div,
        canvas {
            width: 100% !important;
            height: 100% !important;
        }

        .chartTipTitle {
            font-weight: 500;
        }

        .chartTip,
        .pieChartTip {
            display: flex;
            align-items: center;
            justify-content: center;

            .leftBox {
                div {
                    height: 24px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #535353;
                    text-align: right;
                }
            }

            .rightBox {
                margin-left: 20px;

                div {
                    height: 24px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    font-size: 14px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #535353;
                    text-align: right;
                    font-weight: 500;
                }
            }
        }
    }

    .shareProportionBox2 {
        width: 18%;
        min-width: 250px;
        aspect-ratio: 0.8 / 1;


        .shareProportionTitle {
            height: 24px;
            width: 100%;
            text-align: center;
            font-size: 14px;
            // font-weight: 500;
            color: #999999;
            line-height: 24px;
            // margin-bottom: 10px;
            white-space: nowrap; //不支持换行
            overflow: hidden; //隐藏多出部分文字
            text-overflow: ellipsis; //用省略号代替多出部分文字
        }

        .echartTipBox {
            width: 100%;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            // margin-bottom: 10px;
            div {
                font-size: 10px;
                font-family: PingFang SC Regular-Regular, PingFang SC Regular;
                font-weight: 400;
                color: #313131;
                display: flex;
                align-items: center;
                justify-content: center;

                &:nth-of-type(1) {
                    i {
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: #61D4AD;
                        margin-right: 10px;
                    }
                }

                &:nth-of-type(2) {

                    i {
                        display: block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: #0068B2;
                        margin-right: 10px;
                    }
                }
            }
        }

        #shareProportionBox2 {
            width: 100%;
            height: calc(100% - 24px);

        }

        .shareProportionBoxBg2 {
            width: 100%;
            height: calc(100% - 60px);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                display: block;
                height: 80%;
                width: auto;
                cursor: pointer;
            }
        }
    }
}

.empPopover {
    padding-bottom: 0px;
    padding-right: 40px;

    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner {
        border-radius: 4px;
        box-shadow: 0px 3px 8px 0px rgb(0 0 0 / 16%);
    }

    .ant-popover-inner-content {
        padding: 8px 10px;
    }
}

.pieChartTips222 {
    // max-width: 250px;

    .pieChartTitle {
        width: 100%;
        font-weight: bold;
        font-size: 14px;
    }

    .chartTip,
    .pieChartTip2 {
        display: flex;
        align-items: center;
        justify-content: center;
        // flex-wrap: wrap;

        .leftBox {
            div {
                height: 24px;
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #535353;
                text-align: right;
            }
        }

        .rightBox {
            margin-left: 10px;

            div {
                height: 24px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: end;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #535353;
                text-align: right;
                font-weight: 500;
            }
        }
    }
}
