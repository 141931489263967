$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.verificationToolContent {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: $mainPadding;

    .content {
        width: 100%;
        height: calc(100% - 30px);

        .createBtnBox {
            width: 369px;
            height: 60px;
            border-radius: 8px 8px 8px 8px;
            opacity: 1;
            border: 1px solid #D8D8D8;
            font-size: 18px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            padding: 0 24px;
            color: #313131;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 24px;
            margin-left: 15px;
            cursor: pointer;
            user-select: none;
            box-sizing: border-box;

            &:hover {
                border: 1px solid #EAF2FC;
                // color: #0068B2;
                background: #EAF2FC;
            }

            .btnContent {
                display: flex;
                align-items: center;
                justify-content: center;

                .wordIcon {
                    margin-right: 8px;

                    div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }

            .btnIcon {
                font-size: 20px;
            }
        }

        .tableContentBox {
            width: 100%;
            height: calc(100% - 140px);

            .iconBox {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 16px;
                font-weight: 500;
                cursor: pointer;
                user-select: none;
                // flex-direction: row-reverse;
                margin-bottom: 12px;
                color: #313131;

                .anticon:hover {
                    color: #0068B2;

                    path {
                        fill: #0068B2;
                    }
                }

                .anticon {
                    margin-right: 16px;

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }

            .emptyContent {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .notCur {
                td {
                    cursor: default;
                }
            }
        }

        .ant-spin-container,
        .ant-spin-nested-loading {
            width: 100%;
            height: 100%;
        }

        .ant-spin-nested-loading>div>.ant-spin {
            max-height: unset !important;
        }
    }

    .orderTitle {
        position: relative;
        margin: 24px 0 24px 15px;
        font-size: 14px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #313131;
        display: flex;
        align-items: center;
        box-sizing: border-box;

        .icon {
            margin-right: 5px;

            div {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .paginationBox {
        margin-top: 13px;
        display: flex;
        flex-direction: row-reverse;
    }

    .myLoadingIcon {
        width: 24px;
        height: 24px;
        margin-bottom: 5px;
    }

}

.filterDropdownBtnBox22 {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .ant-btn {
        width: 60px;
        margin-left: 0
    }

    .noBg {
        padding: 0px;
    }
}

.createCheckComContent {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: $mainPadding;

    .createContentBox {
        width: 100%;
        height: calc(100% - 120px);
        margin-top: 12px;
        display: flex;
        justify-content: center;

        .noData {
            height: 100%;
            height: 100%;
            @include flex-c-c;
            flex-direction: column;

            .ant-empty-image {
                height: 160px;
            }

            .createBtnBox {
                height: 80px;
                width: 600px;
                margin-top: 60px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;

                .createBtnItem {
                    width: 80px;
                    height: 80px;
                    background: #66A4D1;
                    box-shadow: 0px 4px 10px 0px rgba(0, 104, 178, 0.3);
                    border-radius: 50%;
                    font-size: 12px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    @include flex-c-c;
                    cursor: pointer;
                    flex-direction: column;

                    .createBtnItemIcon {
                        margin-bottom: 5px;

                        div {
                            height: 32px;
                            width: 32px;
                        }

                        svg {
                            height: 32px;
                            width: 32px;

                            path {
                                fill: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }

        .tableContentBox {
            // max-height: 400px;
            width: 100%;

            .selectItem {
                td {
                    background: #f2f6fa !important;
                }
            }

            .iconBox {
                width: 100%;
                display: flex;
                align-items: center;
                font-size: 20px;
                font-weight: 500;
                cursor: pointer;
                user-select: none;
                flex-direction: row-reverse;
                margin-bottom: 12px;
                color: #313131;

                .clearAll {

                    div,
                    svg {
                        height: 20px;
                        width: 20px;
                    }

                    div {
                        @include flex-c-c;
                    }

                    path {
                        fill: #313131;
                    }
                }

                .clearAll:hover {
                    path {
                        fill: #0068B2;
                    }
                }

                .anticon:hover {
                    color: #0068B2;

                    path {
                        fill: #0068B2;
                    }
                }

                .anticon {
                    margin-right: 16px
                }
            }

        }

        .ruleContentBox {
            width: 600px;
            margin-top: 12px;

            .itemContentBox {
                display: flex;
                margin: 20px 0;
                flex-wrap: wrap
            }

            .checkBox {
                @include flex-c-c;
                height: 40px;
                background: #EFEFEF;
                border-radius: 4px 4px 4px 4px;
                padding: 0 10px;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #535353;
                margin: 0px 10px 20px 14px;
                cursor: pointer;
                position: relative;

                .iconCircle {
                    display: block;
                    width: 14px;
                    height: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: -7px;
                    right: -7px;
                    background: #bbbbbb;
                    font-size: 8px;
                    border-radius: 50%;

                    .anticon {
                        color: #fff;
                    }
                }

            }

            .checkedBox {
                color: #FFFFFF;
                background: #0068B2;

                .iconCircle {
                    background: #61D4AD !important;
                }
            }

            .checkboxItem {
                width: 50%;
                padding-left: 14px;

                .ant-checkbox-disabled+span {
                    color: #0068B2 !important;
                }
            }

            .numberInputBox {
                // margin-top: 10px;
                width: 100%;
                padding-left: 14px;
                display: flex;
                align-items: center;
                position: relative;

                .numberInput {
                    width: calc(100% - 135px);
                    margin: 0 8px;
                }

                .inputErrorMsgBox {
                    position: absolute;
                    top: 34px;
                    left: 120px;
                    font-size: 12px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FE545F;
                }
            }

            .recoveryBtnBox {
                margin-top: 24px;

                .recoveryBtn {
                    width: 80px;
                    height: 28px;
                    @include flex-c-c;
                    font-size: 12px;

                    .anticon {
                        transform: rotate(90deg);
                    }
                }
            }
        }
    }

    .createContentBtnBox {
        width: 100%;
        height: 40px;
        margin-top: 10px;
        @include flex-c-c;

        .ant-btn {
            width: 188px;
            height: 40px;
        }
    }
}

.addDrawer2 {
    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        // padding-bottom: 5px;
        // padding-top: 20px;
        padding: 20px
    }

    .addCheckForm {
        width: 100%;
        @include flex;
        @include flex-j-s-b;
        flex-wrap: wrap;

    }


}

.checkDetailsContent {
    width: 100%;
    height: 100%;
    background: #fff;
    padding: $mainPadding;

    .titleBox {
        width: 100%;
        text-align: center;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 24px;
        color: #313131;
        margin-top: 10px;
    }

    .iconsBox {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 10px;

        span {
            font-weight: 400;
            font-size: 14px;
            color: #9E9E9E;
        }

        .icons {
            display: flex;
            align-items: center;

            span {
                font-weight: 400;
                font-size: 14px;
                color: #313131;
                cursor: pointer;
                margin-left: 5px;
                display: flex;
                align-items: center;

                &:hover {
                    color: #0068B2;

                    .anticon {
                        color: #0068B2;
                    }
                }
            }

            .notClick {
                color: #9e9e9e;
                cursor: not-allowed;

                .anticon {
                    color: #9e9e9e !important;
                    cursor: not-allowed !important;
                }

                &:hover {
                    color: #9e9e9e;

                    .anticon {
                        color: #9e9e9e;
                    }
                }
            }
        }
    }

    .iconBox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        user-select: none;
        // flex-direction: row-reverse;
        margin-bottom: 12px;
        color: #313131;

        .anticon:hover {
            color: #0068B2;

            path {
                fill: #0068B2;
            }
        }

        .anticon {
            margin-right: 16px;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .tableBoxContent {
        width: 100%;
        height: calc(100% - 170px);

        .ant-spin-container,
        .ant-spin-nested-loading {
            width: 100%;
            height: 100%;
        }

        .ant-spin-nested-loading>div>.ant-spin {
            max-height: unset !important;
        }

        .notCur {
            td {
                cursor: default;
            }
        }

        .selectItem {
            td {
                background: #f2f6fa !important;
            }
        }
    }

    .btnBox {
        width: 100%;
        height: 40px;
        margin-top: 10px;
        @include flex-c-c;

        .ant-btn {
            width: 188px;
            height: 40px;
        }
    }

    .paginationBox {
        margin-top: 13px;
        display: flex;
        flex-direction: row-reverse;
    }

    .myLoadingIcon {
        width: 24px;
        height: 24px;
        margin-bottom: 5px;
    }
}

.myLoadingIcon222 {
    width: 14px !important;
    height: 14px !important;

    svg {
        width: 14px !important;
        height: 14px !important;
    }
}