$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.companyCardContent {
    width: 100%;
    min-height: 65px;
    border-bottom: 1px solid #EFEFEF;
    overflow-x: hidden;

    .topBox {
        width: 100%;
        display: flex;
        align-items: center;
        // justify-content: center;

        .indexBox {
            width: 40px;
            height: 38px;
            display: flex;
            align-items: center;
            font-size: 24px;
            font-family: Tw Cen MT Condensed-Bold, Tw Cen MT Condensed;
            font-weight: bold;
            color: #9E9E9E;
            position: relative;

            .aaa {
                width: 0;
                height: 0;
                border: 15px solid #ffffff;
                border-left: 15px solid transparent;
                border-top: 15px solid transparent;
                position: absolute;
                bottom: 0px;
                right: 2px;
                z-index: 9;
            }

            .bbb {
                width: 0;
                height: 0;
                border: 12px solid #D8D8D8;
                border-left: 12px solid transparent;
                border-top: 12px solid transparent;
                position: absolute;
                bottom: 3px;
                right: 6px;
                z-index: 8;
            }
        }

        .titleBox {
            // width: calc(100% - 80px);
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 400;
            color: #313131;

            // word-wrap: break-word;
            &:hover {
                color: #0068B2;
                cursor: pointer;
            }
        }

        .titleActiveBox .iconsBox {
            width: 40px;
            font-size: 14px;
            color: #9E9E9E;

            .anticon {
                cursor: pointer;
                margin-left: 3px;

                &:hover {
                    color: #0068B2;
                }
            }
        }

        .activeIconsBox {
            .anticon:first-of-type {
                color: #0068B2 !important;
            }
        }
    }

    .bottomBox {
        width: calc(100% - 80px);
        margin-left: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tagsBox {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .ant-tag {
                margin-bottom: 6px;
            }
        }

        .ant-tag {
            font-size: 9.5px;
            border-radius: 10px;
            zoom: 0.8;
            background: #EFEFEF;
            border-color: #EFEFEF;
            color: #313131;
        }

        .iconTextBox {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
            color: #9E9E9E;

            .selectItemIcon {
                width: 14px;
                height: 14px;
                margin-right: 5px;

                div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                svg {
                    width: 14px;
                    height: 14px;
                }
            }

            .text {
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #9E9E9E;

                span {
                    color: #9E9E9E;
                }
            }
        }

        .activeIconTextBox {
            .text {
                color: #313131;
            }
        }
    }
}