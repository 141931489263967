$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.bondProjectInfoContent {
    @mixin bg-br {
        background: #fff;
        border-radius: 10px;
    }

    height: 100%;
    width: 100%;
    @include flex;
    @include flex-j-s-b;


    .leftBox {
        width: 100%;
        height: 100%;

        .topBox {
            @include flex-c-c;
            width: 100%;
            height: 130px;
            margin-bottom: 20px;
        }

        .leftTop {
            height: 100%;
            width: 100%;
            @include bg-br;
            padding: $mainPadding;

            .leftTopTitle {
                font-family: PingFang SC, PingFang SC;
                font-weight: bold;
                font-size: 16px;
                color: #313131;
                margin: 10px 0 0 10px;
            }

            .leftTopContent {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;

                .itemBox {
                    margin-left: 10px;
                }

                .itemBox,
                .dateBox {
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .labelBox {
                        color: #9E9E9E;
                        margin-left: 40px;

                        &:first-of-type {
                            margin-left: 0;
                        }
                    }

                    .valueBox {
                        color: #535353;
                        margin-left: 8px;

                        &:first-of-type {
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        .leftBottom {
            height: calc(100% - 150px);
            width: 100%;
            @include bg-br;
            padding: $mainPadding;

            .leftBottomContent {
                width: 100%;
                height: calc(100% - 26px);
                // padding: 10px 0 0 10px;

                .waterfallFlowBox {
                    width: 100%;
                    min-height: 100%;
                    padding-right: 10px;
                    // padding-left: 12px;
                    // margin-top: 14px;
                    display: flex;

                    .lineBox {
                        width: calc(33.33% - 13.333333px);
                        margin-left: 20px;
                        min-height: 100%;

                        .card {
                            width: 100%;
                            background: #FFFFFF;
                            border-radius: 2px 2px 2px 2px;
                            border: 1px solid #D8D8D8;
                            margin-bottom: 20px;

                            .cardHeader {
                                width: 100%;
                                height: 48px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                padding: 16px;
                                font-weight: bold;
                                font-size: 14px;
                                color: #313131;
                                border-bottom: 2px solid #D8D8D8;
                                box-sizing: border-box;

                                .numberSpan {
                                    color: #FE545F;
                                    font-size: 16px;
                                }

                                .clickIcon {
                                    color: #9E9E9E;
                                    cursor: pointer;

                                    &:hover {
                                        color: #0068B2;
                                    }
                                }
                            }

                            .cardContent {
                                padding: 6px 16px;
                                width: 100%;
                                height: 100%;

                                .cardItem {
                                    display: flex;
                                    width: 100%;
                                    margin-bottom: 14px !important;

                                    &:last-of-type {
                                        margin-bottom: 0px !important;
                                    }
                                }

                                .colorSpan {
                                    display: inline-block;
                                    height: 5px;
                                    width: 5px;
                                    margin-right: 5px;
                                    margin-top: 8px;
                                }

                                .ant-typography {
                                    width: calc(100% - 10px);
                                    margin-bottom: 0px !important;

                                    // display: flex;
                                    // align-items: center;
                                    // flex-wrap: wrap;
                                    .infoSpan {
                                        color: #535353;
                                        cursor: pointer;
                                    }

                                    // .infoSpan {
                                    //     line-height: 20px !important;

                                    //     span {
                                    //         font-size: 14px !important;
                                    //         line-height: 20px !important;
                                    //     }

                                    //     img {
                                    //         display: inline !important;
                                    //         width: auto !important;
                                    //         height: 14px !important;
                                    //         margin: 0 4px !important;
                                    //         vertical-align: top !important;
                                    //         margin-top: 3px !important;
                                    //     }

                                    // }
                                }



                                .itemLine {
                                    margin-bottom: 10px;

                                    &:last-of-type {
                                        margin-bottom: 0;
                                    }
                                }
                            }

                        }

                        .card:last-of-type {
                            margin-bottom: 0px;
                        }
                    }

                    .lineBox:first-of-type {
                        margin-left: 0px
                    }
                }
            }

        }
    }
}

.subjectItemParagraphTooltip {
    max-width: 450px;

    .ant-tooltip-inner {
        max-height: 300px;
        overflow-y: auto;
        word-break: break-all;
        @include scrollbar;
    }

    .infoSpan {
        color: #535353;
    }

    // .infoSpan {
    //     display: inline-block;
    //     width: 100%;
    //     max-height: 225px;
    //     overflow-y: auto;
    //     @include tooltipScrollbar;
    //     // align-items: center;
    //     // flex-wrap: wrap;

    //     span {
    //         font-size: 14px !important;
    //         line-height: 20px !important;
    //     }

    //     img {
    //         width: auto !important;
    //         height: 14px !important;
    //         margin: 0 4px !important;
    //         vertical-align: top !important;
    //         margin-top: 3px !important;
    //     }

    // }
}