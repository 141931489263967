$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.summaryAssemblyContent1 {
    width: 100%;

    .headerBox {
        height: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-family: PingFang SC-粗体, PingFang SC;
        font-weight: bold;
        color: #313131;

        .headerBoxTitle {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .block {
            display: inline-block;
            width: 2px;
            height: 16px;
            background: linear-gradient(223deg, #006BB3 0%, #15D2D6 100%);
            margin-right: 10px;
        }

        .downloadIcon {
            font-size: 16px;
            cursor: pointer;
        }
    }

    .numberContentBox {
        margin-top: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .numberItem {
            // width: 20%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .numberItemTitle {
                font-weight: bold;
                font-size: 18px;
                color: #0068B2;
                text-align: center;
            }

            .numberItemText {
                font-weight: 400;
                font-size: 40px;
                height: 60px;
                color: #FEC054;
                text-align: center;

                span {
                    font-weight: 400;
                    font-size: 16px;
                    color: #787878;
                }
            }
        }

        .line {
            height: 60px;
            width: 1px;
            background: #EEEEEE;
        }
    }

    .describeContentBox {
        margin-top: 20px;

        .describeItem {
            font-weight: 400;
            font-size: 12px;
            color: #535353;
            line-height: 24px;
            display: flex;

            i {
                display: block;
                width: 5px;
                height: 5px;
                background: #D8D8D8;
                border-radius: 50%;
                margin-right: 5px;
                margin-top: 9.5px;
            }

            div {
                width: calc(100% - 10px);
            }

            span {
                display: inline;
                // color: #0068B2;
                margin: 0 2px;
            }
        }
    }
}

.summaryNumberItemPopover {
    padding-left: 0 !important;

    .ant-popover-inner-content {
        padding: 5px !important;
    }

    .popoverContentDom {
        .popoverContentDomTitle {
            font-size: 16px;
            font-weight: bold;
        }

        .popoverContentDomText {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: bold;

            span {
                &:nth-of-type(2) {
                    font-weight: 400;
                    margin-left: 20px;
                    font-size: 12px;
                    color: #313131;
                }
            }
        }
    }

}