$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.dialogDrawer {
    z-index: 100;

    .ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }

    .ant-drawer-body {
        padding: 0px
    }


    .dialogDrawerForm {
        width: 100%;
        padding: $mainPadding;
        @include flex;
        @include flex-j-s-b;
        flex-wrap: wrap;
        padding-bottom: 0;
    }

    .ant-form-item-label {
        width: 100%;

        label {
            width: 100%
        }
    }

    .jsonHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .anticon {
            color: #9E9E9E;
            font-size: 14px;
            cursor: pointer;
        }
    }

    .isMapBox {
        width: 100%;
        position: relative;
        margin-bottom: 24px;

        .isMapAdd {
            position: absolute;
            right: 6px;
            top: 4px;
            cursor: pointer;
        }
    }

    .mapHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 8px;

        .anticon {
            color: #9E9E9E;
            font-size: 14px;
            cursor: pointer;
        }

        span {
            font-size: 12px;
            color: red;
        }
    }

    .jsonItem {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        .jsonInput {
            display: flex;
            width: calc(100% - 20px);
        }
    }

    .tableItem {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        .ant-form-item{
            margin-bottom: 0px;
        }
        .tableInputContent {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% - 20px);
            border: 1px dashed #9E9E9E;
            padding: 10px;
            border-radius: 5px;

            .tableInput {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
            }

            .ant-radio-group {
                display: flex;
                align-items: center;
                justify-content: center;
                .ant-radio-wrapper{
                    span.ant-radio+*{
                        padding: 0 0 0 4px !important;
                    }
                }
            }
        }
    }

    .imgBox {
        width: 110px;
        height: 110px;
        position: relative;

        .imgCloseIcon {
            display: none;
            position: absolute;
            color: #9E9E9E;
            font-size: 16px;
            top: -8px;
            right: -8px;
            background: #fff;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    .imgBox:hover {
        img {
            border: 1px dashed #D8D8D8;
        }

        .imgCloseIcon {
            display: block;
        }
    }

    .uploadBox {
        width: 110px;
        height: 110px;
        @include flex-c-c;
        align-content: center;
        flex-wrap: wrap;

        .uploadSvg {
            height: 36px;
            width: 36px;
            color: #D8D8D8;

            svg {
                height: 36px;
                width: 36px;
            }
        }

        .text {
            font-size: 12px;
            font-weight: normal;
            color: #9E9E9E;
            padding: 0 3px;

            span {
                color: #0068B2;
            }
        }

        .ant-spin-text {
            font-size: 12px;
            font-weight: normal;
            color: #9E9E9E;
        }
    }

    .ant-upload.ant-upload-drag .ant-upload {
        padding: 0px;
    }

    .ant-upload.ant-upload-drag {
        height: 110px;
    }

    .imgContentBox {
        display: flex;
        flex-wrap: wrap;

        .imgBox {
            margin-bottom: 10px;
            margin-right: 20px;
        }
    }

    .fileContentBox {
        .fileLineItem {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;
            cursor: pointer;
        }

        .fileItem {
            @include flex-c-c;

            .ant-typography {
                margin-left: 5px;
                margin-bottom: 0;
            }
        }

        .closeIcon:hover {
            color: #0068B2;
        }
    }
}