$mainPadding : 20px;

@mixin flex {
    display: flex;
}

@mixin flex-c-c {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-a-c {
    align-items: center;
}

@mixin flex-j-c {
    justify-content: center;
}

@mixin flex-j-s-b {
    justify-content: space-between
}

@mixin flex-a-s-b {
    align-content: space-between;
}

@mixin scrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #eee;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    & {
        scrollbar-width: thin;
        scrollbar-color: #c1c1c1 #eee;
    }
}

@mixin tooltipScrollbar {
    &::-webkit-scrollbar {
        width: 4px;
        height: 100%;
        border-radius: 4px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {


        background: #eee;
        border-radius: 24px 24px 24px 24px;
        height: 36px;
        opacity: 1;
        width: 4px;

        &:hover {
            background-color: #a8a8a8;
        }

        &:active {
            background-color: #787878;
        }
    }

    // & {
    //     scrollbar-width: thin;
    //     scrollbar-color: #c1c1c1 #eee;
    // }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-left: 1.5px solid transparent;
        border-radius: 4px;
        border-right: 1.5px solid transparent;
        box-shadow: inset 0 0 5px #ddd;
    }
}
.tenderingAndSupplierAnalysisContent {
    width: 100%;
    height: calc(100% - 15px);
    background: #fff;

    .dataInput {
        font-size: 14px;
        font-family: PingFang SC-中等, PingFang SC;
        font-weight: normal;
        color: #9E9E9E;
        // @include flex-c-c;
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .inputBox {
            @include flex-c-c;
            margin-left: 20px;
            width: 460px;

            .label {
                font-size: 14px;
                color: #313131;
                width: 60px;
                flex: none;
            }

            .ant-select {
                border-bottom: 1px solid rgba(0, 0, 0, 0.0800);
                box-sizing: border-box;
            }
        }
    }

    .checkBox {
        overflow-y: auto;
        @include scrollbar;
        width: 100%;
        // padding: 0 16px;
        height: calc(100% - 85px);
        margin-top: 12px;
        padding-right: 10px;
    }

    .lineDiv {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 22px;
        width: 100%;
        font-size: 14px;
        font-family: PingFang SC-中等, PingFang SC;
        font-weight: normal;
        color: #0068B2;

        i {
            display: inline-block;
            width: 100%;
            height: 1px;
            background: #0068B2;
        }

        span {
            display: inline-block;
            flex: none;
            padding: 0 16px;
        }
    }

    .chartsBox {
        width: 100%;
        // height: calc(100% - 50px);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .chartsLeftBox {
            width: 450px;
            height: 100%;

            .chartBox:first-of-type {
                margin-top: 0px;
                padding-right: 36px;
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                border-right: 1px solid #EFEFEF;
            }
        }

        .chartsRightBox {
            width: calc(100% - 450px);
            height: 100%;
            padding-left: 36px;

            // margin-left: 20px;
            .chartsRightScrollbarsBox {
                div {
                    &:nth-of-type(3) {
                        z-index: 55;
                    }
                }
            }
        }

        .chartBox {
            width: 100%;
            // height: 330px;
            // aspect-ratio: 2.4 / 1;
            padding-right: 12px;
            background: #FFFFFF;
            // border-radius: 10px 10px 10px 10px;
            opacity: 1;
            // border: 1px solid rgba(0, 104, 178, 0.1);
            flex: none;
            margin-top: 60px;
            box-sizing: border-box;

            // &:nth-of-type(1) {
            //     margin-top: 0;
            // }

            .headerTitleBox {
                font-size: 14px;
                font-family: PingFang SC-粗体, PingFang SC;
                font-weight: bold;
                color: #313131;
                // padding: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .anticon {
                    font-size: 16px;
                    cursor: pointer;
                }
            }

            .tableContentBox {
                height: calc(100% - 54px);
                width: 100%;
                // padding: 0 16px 16px 16px;
                // display: flex;
                // flex-direction: column;
                // justify-content: space-between;

                .paginationBox {
                    margin-top: 13px;
                    display: flex;
                    flex-direction: row-reverse;
                }

                .searchBox {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    // justify-content: space-between;
                    margin-bottom: 12px;
                    margin-top: 12px;

                    .searchItemBox {
                        width: 80px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        user-select: none;
                        cursor: pointer;

                        &:hover {
                            color: #0068B2;
                        }

                        &:focus {
                            color: #0068B2;
                        }
                    }

                    .searchBoxSelectItem {
                        width: calc(100% - 76px);
                        // margin: 0 0 0 16px;
                        display: flex;
                        align-items: center;
                        position: relative;

                        .searchBoxSelectIcon {
                            position: absolute;
                            font-size: 16px;
                            color: #bfbfbf;
                            top: 9px;
                            left: 8px;
                            z-index: 56;
                        }
                    }

                    .searchBoxSelect {
                        .ant-select-selector {
                            border-radius: 18px !important;
                        }

                        .ant-select-selection-search,
                        .ant-select-selection-placeholder {
                            padding-left: 16px;
                        }
                    }
                }

                .tipTextContentBox {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 14px;
                    padding: 0 20px;
                    color: #535353;
                    margin-bottom: 10px;

                    .span1 {
                        font-weight: bold;
                    }

                    .span2 {
                        color: #0068B2;
                        font-weight: bold;
                    }

                    .span3 {
                        cursor: pointer;

                        &:hover {
                            color: #0068B2;
                        }
                    }
                }

                .sortContentBox {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 400;
                    font-size: 14px;
                    margin-top: 12px;
                    color: #535353;

                    .sortIconItem {
                        cursor: pointer;
                        margin-right: 20px;
                        display: flex;
                        align-items: center;

                        &:hover {
                            color: #0068B2;
                        }

                        .sortReactSvg {
                            margin-left: 3px;
                        }
                    }

                    .sortIconActiveItem {
                        color: #0068B2;
                    }

                    .sortItem {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;

                        .sortItemText {
                            margin-left: 10px;
                            color: #0068B2;
                            display: flex;
                            align-items: center;

                            .sortReactSvg {
                                margin-left: 2px;
                            }

                            path,
                            svg,
                            rect {
                                fill: #0068B2;
                            }
                        }
                    }

                    .handleIconBox {
                        display: flex;
                        align-items: center;

                        .searchItemBox {
                            // width: 80px;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            user-select: none;
                            cursor: pointer;
                            margin-left: 10px;

                            .anticon {
                                margin-right: 2px;

                                svg {
                                    width: 14px;
                                    height: 14px;
                                }
                            }

                            &:hover {
                                color: #0068B2;

                                svg {
                                    fill: #0068B2;
                                }
                            }

                            &:focus {
                                color: #0068B2;

                                svg {
                                    fill: #0068B2;
                                }
                            }
                        }
                    }
                }

                .dividerBox {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin-top: 12px;

                    span {
                        display: inline-block;
                        width: 100%;
                        height: 1px;
                        background: #9E9E9E;
                    }
                }

                .searchCompanyBox {
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                }
            }

        }

    }

}

.searchOptionsPopover {
    padding-top: 0px;
    padding-left: 5px;
    max-width: 300px;
    max-height: 400px;

    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner {
        border-radius: 4px;
        box-shadow: 0px 3px 8px 0px rgb(0 0 0 / 16%);
    }

    .ant-popover-inner-content {
        padding: 6px 0px;

    }

    .select-item-option-content {
        width: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 4px 12px;

        &:hover {
            background-color: #efefef;
        }

        .selectOptionBox {
            width: 100%;
            height: 100%;
            display: flex;
        }

        .textItem {
            color: rgba(0, 0, 0, .85);
            // max-width: calc(100% - 40px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .ant-tag {
            margin: 0 0 0 5px;
            padding: 0 1px;
            font-size: 12px;
            transform: scale(0.8);
            font-weight: normal !important;
            border-radius: 4px 4px 4px 4px;
            height: 20px;
            min-width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: none;
        }

        .selectIcon {
            display: none;
        }
    }

    .select-active-item-option-content {
        background-color: #daecf2;

        .textItem {
            font-weight: 600;
        }

        .selectOptionBox {
            width: calc(100% - 20px);
        }

        .selectIcon {
            display: block;
            color: #0068B2;
            flex: none;
            margin-left: 6px;
        }
    }
}

.searchBoxSelectItemTagBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .ant-tag {
        font-size: 9.5px;
        border-radius: 10px;
        zoom: 0.8;
        background: #EFEFEF;
        border-color: #EFEFEF;
        color: #313131;
    }
}

.sortPopover {
    padding-top: 5px !important;

    .ant-popover-inner-content {
        padding: 0px !important;
    }

    .sortPopoverContentBox {
        .sortPopoverItem {
            width: 100%;
            cursor: pointer;
            padding: 4px 8px;
            user-select: none;
            display: flex;
            align-items: center;

            .sortReactSvg {
                color: #313131;
                margin-left: 2px;

                svg,
                path,
                rect {
                    fill: #313131;
                }
            }

            &:hover {
                background: #daecf2;
            }
        }

        .sortPopoverActiveItem {
            background: #daecf2;

            .sortReactSvg {
                color: #0068B2;

                svg,
                path,
                rect {
                    fill: #0068B2;
                }
            }
        }
    }
}